import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Login from "./components/Pages/Login";
import ForgetPassword from "./components/Pages/ForgetPassword";
import TermsAndConditions from "./components/Pages/TermsAndConditions";
import RefundPolicy from "./components/Pages/RefundPolicy";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        <Route path="/refund-and-cencellation" element={<RefundPolicy/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

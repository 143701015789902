import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 60) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const headerHeight = document.querySelector('nav').offsetHeight; // Get the height of the sticky header
        const sections = document.querySelectorAll('div[id]'); // Select all div elements with an ID
    
        const handleScroll = () => {
          let current = '';
    
          sections.forEach(section => {
            const sectionTop = section.offsetTop - headerHeight; // Adjust by header height
            if (window.scrollY >= sectionTop) {
              current = section.getAttribute('id');
            }
          });
    
          setActiveSection(current);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        const navLinks = document.querySelectorAll('.nav-item a');
    
        navLinks.forEach(link => {
          link.addEventListener('click', (e) => {
            e.preventDefault();
            const targetId = link.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);
            window.scrollTo({
              top: targetElement.offsetTop - headerHeight, // Scroll to the target element adjusted by header height
              behavior: 'smooth',
            });
          });
        });
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          navLinks.forEach(link => link.removeEventListener('click', () => {}));
        };
      }, []);
    


    return (
        <header className={`navbar-area py-3 ${isSticky ? 'is-sticky' : ''}`}>
            <div className="container">
                <div className="header-content">
                    <a href="/" className="logo">
                        <img src="/Pocket-1-logo.png" alt="Logo" />
                    </a>

                    <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                        <ul className="navbar-nav">
                            <li className={`nav-item ${activeSection === 'hero' ? 'active' : ''}`}>
                                <a href="#hero" className={`${activeSection === 'hero' ? 'active' : ''}`}>Home</a>
                            </li>
                            <li className={`nav-item ${activeSection === 'about-us' ? 'active' : ''}`}>
                                <a href="#about-us" className={`${activeSection === 'about-us' ? 'active' : ''}`}>About Us</a>
                            </li>
                            <li className={`nav-item ${activeSection === 'service' ? 'active' : ''}`}>
                                <a href="#service" className={`${activeSection === 'service' ? 'active' : ''}`}>Service</a>
                            </li>
                            <li className={`nav-item ${activeSection === 'contact' ? 'active' : ''}`}>
                                <a href="#contact" className={`${activeSection === 'contact' ? 'active' : ''}`}>Contact</a>
                            </li>

                            <div className="login-link ms-3">
                                <Link to={'/login'} className='btn theme-btn-1  wow animate__animated animate__fadeInUp'>Login</Link>
                            </div>
                        </ul>

                    </nav>


                    <div className="menu-icon" onClick={toggleMenu}>
                        <i className="ri-menu-line"></i>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header

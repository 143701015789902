import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    useEffect(() => {
        const headerHeight = document.querySelector('nav')?.offsetHeight || 0;
        const footerLinks = document.querySelectorAll('.quick-links a');

        footerLinks.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const targetId = link.getAttribute('href').substring(1);
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    window.scrollTo({
                        top: targetElement.offsetTop - headerHeight, // Adjust by header height
                        behavior: 'smooth',
                    });
                }
            });
        });

        return () => {
            footerLinks.forEach(link => link.removeEventListener('click', () => { }));
        };
    }, []);

    return (
        <footer className="footer-style bg-gray-100 pt-200">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="footer-logo mt-3 mb-3">
                            <a href="#"><img src="/Pocket-4-logo.png" alt="" /></a>
                        </div>
                    </div>
                </div>

                <div className="footer-middle-area mt-3 pb-30 pt-60">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title">About Us</h3>
                                <div className="footer-text">
                                    <p>PocketPay offers a fast and secure nano banking solution designed to simplify your everyday transactions.</p>
                                </div>
                            </div>
                        </div>
                        {/* Address Section */}
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title">Address</h3>
                                <div className="footer-text-para" style={{ color: '#7f839c' }}>
                                    <p>R8-1786 Shop NO A-405 Ausa Road, Nandi Stop, Latur, Maharashtra. 413512</p>
                                    <p>
                                        Email : <a href="mailto:admin@mypocketpay.co.in" className='footer-email'>admin@mypocketpay.co.in</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title">Useful Links</h3>
                                <div className="footer-link">
                                    <ul>
                                        <li>
                                            <Link to={'/terms-and-conditions'} target="_blank" rel="noopener noreferrer">
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/refund-and-cencellation'} target="_blank" rel="noopener noreferrer">
                                                Refunds & Cancellation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/privacy-policy'} target="_blank" rel="noopener noreferrer">
                                                Privacy & Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title">Quick Links</h3>
                                <div className="footer-link quick-links">
                                    <ul>
                                        <li><a href="#hero">Home</a></li>
                                        <li><a href="#about-us">About Us</a></li>
                                        <li><a href="#service">Our Services</a></li>
                                        <li><a href="#contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>

                <div className="footer-bottom-area py-4">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="copyright">
                                <p>© Copyrights 2024 <a href="#">PocketPay.</a> All rights reserved.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="footer-bottom-link text-end"></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;


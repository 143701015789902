import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, Button, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';

const services = [
    { img: '/images/icons/recharge.png', text: 'Mobile Recharge' },
    { img: '/images/icons/post-paid.png', text: 'Post Paid' },
    { img: '/images/icons/DTH.png', text: 'DTH' },
    { img: '/images/icons/utilitiy-payment.png', text: 'Utility Payment' },
    { img: '/images/icons/money-transfer.png', text: 'Money Transfer' },
    { img: '/images/icons/upi.png', text: 'QR | UPI' },
    { img: '/images/icons/bbps.png', text: 'BBPS' },
    { img: '/images/icons/cms.png', text: 'CMS' },
    { img: '/images/icons/aeps.png', text: 'Aadhar Pay' },
];

const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
    let result = '';
    for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const Login = () => {
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [captchaText, setCaptchaText] = useState(generateCaptcha());
    const [errors, setErrors] = useState({ number: '', password: '', captcha: '' });

    const captchaCanvasRef = useRef(null);

    useEffect(() => {
        drawCaptcha(captchaText);
    }, [captchaText]);

    const drawCaptcha = (text) => {
        const canvas = captchaCanvasRef.current;
        const ctx = canvas.getContext('2d');

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Add background color
        ctx.fillStyle = '#f2f2f2';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw distorted random text
        ctx.font = 'bold 30px Arial';
        ctx.fillStyle = '#414141';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        const xOffset = 10;
        const yOffset = 25;
        for (let i = 0; i < text.length; i++) {
            ctx.setTransform(1, Math.random() * 0.3, Math.random() * 0.3, 1, 0, 0);
            ctx.fillText(text[i], xOffset + i * 20, yOffset);
        }

        // Add noise (lines, dots, etc.)
        for (let i = 0; i < 5; i++) {
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
            ctx.stroke();
        }
    };

    const refreshCaptcha = () => {
        setCaptchaText(generateCaptcha());
    };

    const validateForm = () => {
        let formErrors = { number: '', password: '', captcha: '' };
        let isValid = true;

        if (!number) {
            formErrors.number = 'User ID / Mobile No is required.';
            isValid = false;
        }

        if (!password) {
            formErrors.password = 'Password is required.';
            isValid = false;
        }

        if (captchaInput !== captchaText) {
            formErrors.captcha = 'Captcha validation failed.';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            Swal.fire({
                icon: 'success',
                title: 'Login Successful',
                text: 'You have logged in successfully!',
                confirmButtonText: 'Okay'
            }).then(() => {
                // Here, you can redirect the user or perform further actions
                console.log('Login successful:', { number, password });
                // Redirect or perform actions after the alert closes, if needed
            });
        } else {
            // Existing validation error handling
            if (captchaInput === captchaText && (!number || !password)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Input Error',
                    text: 'Please enter valid User ID / Mobile No and Password.',
                    confirmButtonText: 'Okay'
                });
            }
        }
    };

    return (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }
        }}>
            {/* Left side - white background */}
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                padding: { xs: '1rem', md: '2rem' }
            }}>
                <Paper elevation={0} sx={{ width: { xs: '90%', sm: '400px' }, p: 4, borderRadius: '15px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ mb: 2 }}>
                            <img src="/Pocket-1-logo.png" alt="PocketPay Logo" style={{ maxWidth: '200px' }} />
                        </Box>
                        <Typography component="p" sx={{ textAlign: 'center', px: 4, mt: 1, opacity: 0.7, fontSize: '0.875rem' }}>
                            We’re glad to see you again! Please log in to access your account.
                        </Typography>
                        <Box component="form" sx={{ mt: 2 }} noValidate onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="number"
                                label="User Id/ Mobile No"
                                name="number"
                                autoComplete="number"
                                autoFocus
                                size='small'
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                error={!!errors.number}
                                helperText={errors.number}
                                variant="standard"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                size='small'
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={!!errors.password}
                                helperText={errors.password}
                                variant="standard"
                                sx={{ mb: 3 }}
                            />

                            {/* Image-based CAPTCHA */}
                            <Typography sx={{ mb: 1, textAlign: 'center', fontSize: '0.875rem' }}>
                                Please enter the CAPTCHA below:
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <canvas ref={captchaCanvasRef} width="140" height="50" style={{ border: '1px solid #ccc' }}></canvas>
                                <Button onClick={refreshCaptcha} sx={{ padding: '0 10px' }}>Refresh</Button>
                            </Box>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="captcha"
                                label="Enter CAPTCHA"
                                name="captcha"
                                size="small"
                                value={captchaInput}
                                onChange={(e) => setCaptchaInput(e.target.value)}
                                error={!!errors.captcha}
                                helperText={errors.captcha}
                                variant="standard"
                                sx={{ mt: 2 }}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    py: 1.5,
                                    backgroundColor: '#414141',
                                    color: 'white',
                                    borderRadius: '5px',
                                    '&:hover': {
                                        backgroundColor: '#414141',
                                        transform: 'scale(1.01)',
                                        transition: '0.3s ease',
                                    }
                                }}
                            >
                                Login
                            </Button>
                            <Typography align="center" sx={{ mt: 1 }}>
                                <Link to={'/forget-password'} style={{ color: '#1976d2', textDecoration: 'none' }}>Forgot Password?</Link>
                            </Typography>
                            <Typography align="center" sx={{ mt: 1 }}>
                                <Link to="/" style={{ color: '#1976d2', textDecoration: 'none' }}>Back to Home</Link>
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>

            {/* Right side - background and services */}
            <Box sx={{
                flex: 1,
                backgroundColor: '#dc1e31',
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: '1rem', md: '2rem' },
            }}>
                <h3 className='text-white mb-5 text-center'>Most Adorable Services</h3>
                <Box className="services" sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    maxWidth: '100%',
                    gap: '10px',
                    padding: { xs: '10px', md: '20px' }
                }}>
                    {services.map((service, index) => (
                        <Box key={index} className="service-item" sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                            borderRadius: '10px',
                            width: { xs: '80px', md: '120px' },
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                transform: 'scale(.95)',
                                boxShadow: 'none',
                            }
                        }}>
                            <img src={service.img} alt={service.text} style={{ width: '50px', marginBottom: '10px', filter: 'brightness(1.5)' }} />
                            <Typography variant="caption" sx={{ color: 'white', textAlign: 'center', fontSize: { xs: '12px', md: '14px' }, lineHeight: '1.2' }}>
                                {service.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Login;


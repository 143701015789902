import React from 'react'

const ContactUs = () => {
    return (
        <div className='contact-us pb-60 pt-60' id='contact'>
            <div className="container">
                <div className="row py-4">
                    <div className="col-12 px-3">
                        <h4>Get in Touch With Us</h4>
                        <p>We save you not only time, but in many cases also many thousands of kroner. Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
                        <div className="row px-3 p-sm-5">
                            <div className="col-12">
                                <div className="row mt-3">
                                    <div className="col-sm-6">
                                        <div className="contact-box">
                                            <label htmlFor="FullName" className='mb-2'> Full Name</label>
                                            <input type="text" placeholder='What is Your Name' id='FullName' />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="contact-box">
                                            <label htmlFor="email" className='mb-2'> Email</label>
                                            <input type="email" placeholder='YourName@example.cpm' id='email' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="contact-box mt-3">
                                    <label htmlFor="Subject" className='mb-2'>Subject</label>
                                    <input type="text" id="Subject" placeholder='Your Subject' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="contact-box mt-3">
                                    <label htmlFor="Message" className='mb-2'>Messages</label>
                                    <textarea id="Message" rows={3} placeholder='Your Messages...'></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="submit-btn">
                                <button className='contact-submit'> Submit </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
import React from 'react';

const TransferSolution = () => {
  return (
    <div className="transfer-solution" id='service'>
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-xl-6 mil-mb-80">
          <h2 className="mb-3 mil-up">
            The Perfect Solution for Your Personal Transfers
          </h2>
          <p className="mil-text-m mil-soft mb-3 mil-up">
            Explore the convenience of PocketPay Consumer and <br />
            make your personal transfers quick and easy.
          </p>
          <div class="row g-3">
                        <div class="why-choose-us-content mb-removed">
                            <div class="d-flex justify-content-between flex-wrap mt-3 align-items-center ">
                                <div class="work-itm p-2 col-lg-6 col-md-6 col-sm-12 ">
                                    <div class="d-flex align-items-center bg-white rounded p-3 item-box">
                                        <div class="work-itm-icon">
                                            <img src="/images/icons/money-transfer.png" alt="Aadhar Pay Icon" />
                                        </div>
                                        <div class="ms-3 itm-heading">
                                            <h5>Money Transfer</h5>
                                            <p>Fast, secure, and reliable fund transfers across banks for both customers and businesses.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="work-itm p-2 col-lg-6 col-md-6 col-sm-12 ">
                                    <div class="d-flex align-items-center bg-white rounded p-3 item-box">
                                        <div class="work-itm-icon">
                                            <img src="/images/icons/bbps.png" alt="BBPS Icon" />
                                        </div>
                                        <div class="ms-3 itm-heading">
                                            <h5>BBPS</h5>
                                            <p>Pay bills easily with our Bharat Bill Payment System integration, offering convenience for all utilities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="work-itm p-2 col-lg-6 col-md-6 col-sm-12 ">
                                    <div class="d-flex align-items-center bg-white rounded p-3 item-box">
                                        <div class="work-itm-icon">
                                            <img src="/images/icons/cms.png" alt="CMS Icon" />
                                        </div>
                                        <div class="ms-3 itm-heading">
                                            <h5>CMS</h5>
                                            <p>Our Cash Management System (CMS) offers streamlined services for managing and securing your cash flow.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="work-itm p-2 col-lg-6 col-md-6 col-sm-12 ">
                                    <div class="d-flex align-items-center bg-white rounded p-3 item-box">
                                        <div class="work-itm-icon">
                                            <img src="/images/icons/scalability.png" alt="AEPS Icon" />
                                        </div>
                                        <div class="ms-3 itm-heading">
                                            <h5>AEPS</h5>
                                            <p>Enable customers to conduct banking transactions like withdrawals and deposits using Aadhaar authentication.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
        <div className="col-xl-5 mil-mb-80">
          <div className="mil-image-frame mil-visible-overflow">
            <img src="/Images/transfer-solution.png" alt="image" className="mil-up w-100" />
            <div className="mil-img-box mil-right-max mil-soft-box mil-up" style={{ transform: 'translate(0px, 50px) scale(0.98)', opacity: 0 }}>
              <img src="/Images/icons/4.svg" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TransferSolution;


import './App.css';
import Banks from './components/Layout/Banks';
import Benefits from './components/Layout/Benefits';
import ContactUs from './components/Layout/ContactUs';
import FAQAccordion from './components/Layout/FAQAccordion';
import Footer from './components/Layout/Footer';
import GetinTouchContainer from './components/Layout/GetinTouchContainer';
import GoToTop from './components/Layout/GoToTop';
import Header from './components/Layout/Header';
import HeroSection from './components/Layout/HeroSection';
import Service from './components/Layout/Service';
import ServicePartOne from './components/Layout/ServicePartOne';
import TransferSolution from './components/Layout/TransferSolution';
import UserCounter from './components/Layout/UserCounter';

function App() {
  return (
    <div className="App">
      <Header/>
      <HeroSection/>
      <Benefits/>
      <UserCounter/>
      <TransferSolution/>
      <Service/>
      <GetinTouchContainer/>
      <Banks/>
      <ServicePartOne/>
      <FAQAccordion/>
      <ContactUs/>
      <Footer/>
      <GoToTop/>
    </div>
  );
}

export default App;

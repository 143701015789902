import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy my-5">
      <h1 className='text-center'>Privacy Policy</h1>
      <div className="container mt-5 p-3">

      
      <h5>Welcome to PocketPay</h5>

      <p>
        This Agreement describes the terms and conditions that shall govern the use of and the services to be provided to the User. By registering as a User or by using the site, you hereby agree to be bound by all of the following terms and conditions:
      </p>

      <h6>SMS</h6>
      <p>
        By providing your phone number, you agree to receive SMS from PocketPay.
      </p>

      <p>
        The User hereby agrees that PocketPay, in its sole and absolute discretion, may issue a warning, revoke, suspend, or terminate the User's membership or use of the Service, cancel or suspend its Service, and remove, block (temporarily or permanently) without prior warning and without refunding any deposit for any reason whatsoever. This includes, but is not limited to, cases where PocketPay believes that a User has (I) abused and/or violated either the letter or spirit of the Rules, (ii) breached this User Agreement; or (iii) if the User is found to have engaged in fraudulent activity in connection with our site; or (iv) if PocketPay is unable to verify or authenticate any information provided by the User.
      </p>

      <p>
        The terms and conditions of this User Agreement may be amended, supplemented, and changed by PocketPay at its sole discretion. PocketPay may automatically amend this User Agreement at any time by sending the amended terms to the User's account or any other e-mail address the User provides, or by posting the amended terms on our site. Such amendments will be effective 30 days after being sent or posted. This Agreement may not be amended except in writing signed by PocketPay. PocketPay cannot guarantee, represent, or warrant that the content contained on the site is accurate, legal, and/or inoffensive. By reading the terms and conditions carefully, you agree and understand that you may not make any claim against PocketPay or Web Soft or its partners/owners for any damages or losses resulting from your use of the site.
      </p>

      <h6>Commitment to Privacy</h6>
      <p>
        We are committed to protecting your privacy. We will only use the information we collect about you lawfully.
      </p>

      <p>
        We collect information about you for two reasons: firstly, to process your order and, secondly, to provide you with the best possible service.
      </p>

      <p>
        We will not email you in the future unless you have given us your consent.
      </p>

      <p>
        We will give you the chance to refuse any marketing email from us or from another trader in the future.
      </p>

      <h6>Information We Collect</h6>
      <p>The type of information we will collect about you includes:</p>
      <ul>
        <li>Your Name</li>
        <li>Address</li>
        <li>Phone Number</li>
        <li>Email Address</li>
      </ul>
      <p>
        We will never collect sensitive information about you without your explicit consent.
      </p>

      <p>
        The information we hold will be accurate and up to date. You can check the information we hold about you by emailing us. If you find any inaccuracies, we will delete or correct it promptly.
      </p>

      <p>
        The personal information we hold will be kept securely in accordance with our internal security policy and the law.
      </p>

      <h6>Tracking and Cookies</h6>
      <p>
        We may use technology to track the patterns of behavior of visitors to our site. This can include using a "cookie" which would be stored on your browser. You can usually modify your browser to prevent this happening. The information collected in this way can be used to identify you unless you modify your browser settings.
      </p>

      <h6>Non-Transferability</h6>
      <p>
        Users may not transfer to or share their account with anyone. Users may not disclose their password to any third parties. E-coupons are not transferable and are rechargeable for the period of one year from issuance.
      </p>

      <h6>Changes to Terms</h6>
      <p>
        PocketPay reserves the right to change these Terms at any time, effective immediately upon posting on the site. Please check this page periodically. The content and software on this site is the property of PocketPay and/or its suppliers Web Soft and is protected by copyright laws. If any problems persist during site surfing or any inconvenience, please contact our customer care department at pocketpay.api@gmail.com.
      </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react'
import 'animate.css';

const HeroSection = () => {
  return (
    <div className="hero-1 oh pos-rel" id='hero'>
        <div className="container">
            <div className="row align-items-center o-hidden">
                <div className="col-lg-5 pb-60">
                    <div className="hero-1-content wow animate__animated animate__fadeInLeft">
                        <h5 className="cate  wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">Simple. Transparent. Secure</h5>
                        <h1 className="title  wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">Neno Banking Solutions</h1>
                        <p className=" wow animate__animated animate__fadeInUp" data-wow-delay="0.6s">
                        Empowering you with seamless banking services designed to simplify your financial life.
                        </p>
                        <div className="hero-1-button-group ms-5">
                            <a href="#" className="btn theme-btn-1  wow animate__animated animate__fadeInUp" data-wow-delay="0.8s"> Get
                                Started
                                <i className="uil uil-angle-right-b ml-2 mb-2"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 d-lg-block">
                    <div className="d-flex justify-content-center animate__animated animate__fadeInRight" data-wow-delay="0.4s">
                        <img className="img-fluid animate__animated animate__fadeInRight  hero-img" src="/Images/happy-women.jpg" alt="hero-1"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeroSection

import React from 'react'

const Service = () => {
    return (
        <div className='service-area pt-5' >
            <div className="container">
                <div className="d-flex flex-column">
                    <div className="header">
                        <h2 className='mt-3'>Adapted to your needs, discover what we have</h2>
                        <p>The Service That We Provide For Our Ultimate Clients</p>
                    </div>
                    <div className="service-item-box pt-5">
                        <div className="row g-4">
                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="/images/icons/api-color.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Open API Platform</h4>
                                    <p>A Unified Open API Platform that brings all the solution seamlessly together for all your neo banking solution</p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="images/icons/integration-color.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Profit-Boosting Integration</h4>
                                    <p>Integrate and go live with our easy APIs to maximize your profitability and get the most out of it.</p>

                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="images/icons/bank-color.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Choose Your Bank</h4>
                                    <p>Create a primary and backup bank as a pipe and get the transaction flow basis your preference</p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="images/icons/paperless-color.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Paperless Onboarding</h4>
                                    <p>We believe in zero manual intervention, complate the online onboarding with minimum documentation, and get started</p>

                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="images/icons/dashboard.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Multi-featured Dashboard</h4>
                                    <p> Get wide range of value added services on a single dashboard with batter success rates</p>

                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6 ">
                                <div className="single-service-box">
                                    <div className="image-icon">
                                        <img src="/images/icons/customer-service-color.png" alt="" />
                                    </div>
                                    <h4 className='mt-3'>Dedicated Customer Support</h4>
                                    <p>Always available email, phone and chat based support to helps you in your every steps.</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service

import React, { useState } from 'react';
import { Box, Paper, Button, TextField, Typography, Grid, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const services = [
    { img: '/images/icons/recharge.png', text: 'Mobile Recharge' },
    { img: '/images/icons/post-paid.png', text: 'Post Paid' },
    { img: '/images/icons/DTH.png', text: 'DTH' },
    { img: '/images/icons/utilitiy-payment.png', text: 'Utility Payment' },
    { img: '/images/icons/money-transfer.png', text: 'Money Transfer' },
    { img: '/images/icons/upi.png', text: 'QR | UPI' },
    { img: '/images/icons/payout.png', text: 'Payout' }
];

const ForgetPassword = () => {
    const [mobile, setMobile] = useState('');

    const validateMobile = (mobile) => {
        const re = /^[0-9]{10}$/; // Ensures the number has exactly 10 digits
        return re.test(String(mobile));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mobile && validateMobile(mobile)) {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: `A password reset link has been sent to the mobile number: ${mobile}`,
                confirmButtonText: 'Okay'
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please enter a valid 10-digit mobile number.',
                confirmButtonText: 'Okay'
            });
        }
    };

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        // Only allow number keys
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    const getRandomPosition = () => ({
        top: `${Math.random() * 90}%`,
        left: `${Math.random() * 90}%`,
    });

    return (
        <Grid container sx={{ height: '100vh' }}>
            {/* Left side with red background and service icons */}
            <Grid item xs={12} md={6} sx={{ position: 'relative', backgroundColor: '#dc1e31', overflow: 'hidden' }}>
                <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {services.map((service, index) => {
                        const randomPosition = getRandomPosition();
                        return (
                            <Tooltip title={service.text} key={index}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: '50px',
                                        height: '50px',
                                        ...randomPosition,
                                        opacity: 0.3, // Default lower opacity
                                        transition: 'opacity 0.3s ease', // Smooth transition
                                        '&:hover': {
                                            opacity: 1, // Full opacity on hover
                                        },
                                        animation: `float ${4 + Math.random() * 2}s ease-in-out infinite`,
                                        '@keyframes float': {
                                            '0%': { transform: 'translateY(0px)' },
                                            '50%': { transform: 'translateY(-20px)' },
                                            '100%': { transform: 'translateY(0px)' },
                                        }
                                    }}
                                >
                                    <img src={service.img} alt={service.text} style={{ width: '100%', height: '100%' }} />
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Box>
            </Grid>

            {/* Right side with form */}
            <Grid item xs={12} md={6} sx={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paper elevation={0} sx={{ width: '400px', p: 4, backgroundColor: 'white' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <img src="/Pocket-2.png" alt="Logo" style={{ width: '80%', maxWidth: '150px' }} />
                    </Box>
                    <Typography component="h1" variant="h5" align="center">
                        Forgot Password
                    </Typography>
                    <Typography component="p" sx={{ textAlign: 'center', px: 4, mt: 2, mb: 3, opacity: 0.7, fontSize: '0.875rem' }}>
                        Enter your mobile number to receive a password reset link.
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            label="Mobile Number"
                            name="mobile"
                            autoComplete="tel"
                            autoFocus
                            variant="standard"
                            sx={{ mb: 2 }}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            onKeyPress={handleKeyPress}
                            inputProps={{ maxLength: 10 }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                mb: 2,
                                py: 1.5,
                                backgroundColor: '#414141', // Updated background color
                                color: 'white', // Text color
                                borderRadius: '15px', // Rounded corners
                                '&:hover': {
                                    backgroundColor: '#555555', // Slightly lighter color on hover
                                    transform: 'scale(1.01)', // Slight scale effect on hover
                                    transition: '0.3s ease', // Smooth transition
                                }
                            }}
                        >
                            Send Reset Link
                        </Button>

                        <Typography align="center">
                            <Link to={'/login'} style={{ color: '#1976d2', textDecoration: 'none' }}>Back to Login</Link>
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ForgetPassword;
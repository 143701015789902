import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="refund-policy py-5 px-3">
      <h1 className='text-center'>Refund and Cancellation Policy</h1>

      <div className="container mt-5">
      <h4 className='text-center mb-3'>PocketPay Refund and Cancellation Policy</h4>

      <p>
      Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.
      </p>
<p>In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:</p>
      <h6 className='mt-4'>Cancellation Policy</h6>
      <p>
        For cancellations, please contact us via the "Contact Us" link.
      </p>
      <p>
        Requests received later than 7 business days prior to the end of the
        current service period will be treated as a cancellation of services for
        the next service period.
      </p>

      <h6 className='mt-4'>Refund Policy</h6>
      <p>
        We will try our best to create suitable design concepts for our clients.
      </p>
      <p>
        In case any client is not completely satisfied with our products, we can
        provide a refund.
      </p>
      <p>
        If paid by credit card, refunds will be issued to the original credit
        card provided at the time of purchase. In case of payment gateway name
        payments, refunds will be made to the same account.
      </p>
      </div>
    </div>
  );
};

export default RefundPolicy;

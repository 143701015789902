import React from 'react';

const Benefits = () => {
    return (
        <div className="benefit-section" id='about-us'>
            <div className="container">
                <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                    <div className="col-xl-6 mil-mb-80">
                        <h2 className="mil-mb-30 mil-up">Exclusive Benefits of<br />Using PocketPay</h2>
                        <ul className="mil-list-1">
                            <li>
                                <div className="mil-up">
                                    <h5 className="mil-mb-15 mil-up">Instant Transactions</h5>
                                    <p className="mil-text-m mil-soft mil-up">
                                        Make money transfers immediately, without complications or delays.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="mil-up">
                                    <h5 className="mil-mb-15 mil-up">Guaranteed Security</h5>
                                    <p className="mil-text-m mil-soft mil-up">
                                        Protect your financial information with our advanced security protocols.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="mil-up">
                                    <h5 className="mil-mb-15 mil-up">Extensive Global Coverage</h5>
                                    <p className="mil-text-m mil-soft mil-up">
                                        Access our global network that covers more than 169 countries for your international transactions.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xl-5 mil-mb-80">
                        <div className="mil-image-frame mil-visible-overflow">
                            <img src="/Images/benefits.jpg" alt="image" className="mil-up w-100 benefit-img" />
                            <div className="mil-img-box mil-accent-box mil-up">
                                <div>
                                    <h3 className="mil-light mil-mb-15">100%</h3>
                                    <p className="mil-text-s mil-light">Security in your <br />payments</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Benefits;


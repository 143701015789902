import React from 'react'
import CountUp from 'react-countup';

const UserCounter = () => {
    return (
        <div className="user-counter">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-xl-6 mil-sm-text-center mil-mb-80 mil-up">
                            <p className="h1 mil-display mil-mb-15">
                                <span className="mil-accent">
                                    <CountUp start={0} end={100} duration={5} />
                                </span>
                                <span className="mil-pale">+</span>
                            </p>
                            <h5>Districts and</h5>
                        </div>
                        <div className="col-xl-6 mil-sm-text-center mil-mb-80 mil-up">
                            <p className="h1 mil-display mil-mb-15">
                                <span className="mil-accent">
                                    <CountUp start={0} end={250} duration={5} />
                                </span>
                                <span className="mil-pale">+</span>
                            </p>
                            <h5>Talukas with our coverage</h5>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 mil-mb-80">
                    <p className="mil-text-m mil-soft mil-up">
                        Dive into the data behind PocketPay's success. From the number of users to the extent of our global network, these facts and figures highlight PocketPay's impact on the world of finance and how we have managed.
                    </p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default UserCounter

import React from 'react'

const Banks = () => {
    return (
        <div className='banks-area pb-60 pt-60 o-hidden'>
            <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3 className='text-center'>Banks Who Trust US</h3>
                </div>
                <div className="col-12">
    <div className="row p-3">
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/axisbank.png" alt="Axis Bank" />
            </div>
        </div>
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/kotakbank.png" alt="Kotak Bank" />
            </div>
        </div>
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/icicibank.png" alt="ICICI Bank" />
            </div>
        </div>
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/yesbank.png" alt="Yes Bank" />
            </div>
        </div>
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/hadfcbank.png" alt="HDFC Bank" />
            </div>
        </div>
        <div className="bank-logo">
            <div className="bank-image">
                <img src="/images/bankLogo/bankofbaroda.png" alt="Bank of Baroda" />
            </div>
        </div>
    </div>
</div>

            </div>
            </div>
        </div>
    )
}

export default Banks

import React, { useState } from 'react';

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="FAQ-area" >
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="text-center">
            <h2 className="mil-mb-30 mil-up">
              Explore Our Frequently <br />
              Asked Questions
            </h2>
            <p className="mil-text-m mil-soft mil-mb-60 mil-up">
              Find quick and clear answers to the most common <br />
              questions about PocketPay
            </p>
          </div>
          <div className="mil-accordion">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className={`mil-accordion-group mil-up ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <div className="mil-accordion-menu">
                  <h5>{faq.question}</h5>
                  <div className="mil-accordion-icon">
                    <i className={`fas ${activeIndex === index ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                  </div>
                </div>
                {activeIndex === index && (
                  <div className="mil-accordion-content">
                    <p className="mil-text-m mil-soft">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

// FAQ Data
const faqData = [
  {
    question: 'How can I send money with PocketPay?',
    answer: 'Discover the step-by-step process to make money transfers easily and securely with PocketPay.',
  },
  {
    question: 'What security measures does PocketPay have to protect my transactions?',
    answer: 'PocketPay implements industry-leading security protocols to ensure the safety of your transactions.',
  },
  {
    question: 'What is the coverage of the PocketPay network?',
    answer: 'PocketPay operates in over 10 States, 100+ Dist and 250+ Talukas, connecting users globally with ease.',
  },
  {
    question: 'How can I contact PocketPay customer service?',
    answer: 'You can contact our customer service via the app or visit our support page for more details.',
  },
];

export default FAQAccordion;

import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='termsAndCondition container py-5 px-3'>
      <h1 className='text-center my-5'>Terms & Conditions</h1>
      <p>
      We welcome you to PocketPay website owned by Web Soft. The terms and conditions is an agreement regulating our relationship with regard to the use of PocketPayfor shopping by you, the user. You are advised to read this Agreement carefully. If you are not agreeable to any terms and conditions, you should not use the Services. You can use PocketPay shopping services if you are agreeable to all the terms and conditions of this Agreement in their entirety. By clicking on the space provided on the PocketPay website, you will be deemed to have accepted this Agreement and it will be a legally binding Agreement between you and Web Soft.
      </p>


      <h6 className='mt-5'>Definitions</h6>
     
        <p>"Agreement" means the terms and conditions as detailed herein including all schedules, shipping policy, privacy policy, disclaimers, and will include the references to this Agreement as amended, supplemented, varied or replaced from time to time.</p>
        <p>"Customer" / "Buyer" shall mean the person or any legal entity who accepts the products for sale on PocketPay by placing an order for and or purchases any products offered for sale on.</p>
      

      <h6 className='mt-5'>Eligibility</h6>
      <p>
      You represent and warrant that you are competent and eligible to enter into legally binding agreement and have the requisite authority to bind Web Soft, to this Agreement. You shall not use this Site if you are not competent to contract under the applicable laws, rules and regulations.
      </p>

      <h6 className='mt-5'>Term of Agreement/Service</h6>
      <p>
      This Agreement shall continue to be in full force and effect for so long as you are using PocketPay services. PocketPay provides the online shopping platform wherein the Users can purchase/buy the products listed on PocketPay (the "Services") pursuant to the terms and conditions set forth below. By clicking on the [Close] button, You agree to use the Services in a manner consistent with the terms hereof and also agree to abide by the terms and conditions of this Agreement, including but not limited to the privacy policy, and with all applicable laws and regulations. Either you or PocketPay may terminate the service at any time, with or without cause. However PocketPay reserves the right, in its sole discretion, to terminate your access to the PocketPay services and the related services or any portion thereof at any time, without any notices.
      </p>

      <h6 className='mt-5'>Modification of Terms and conditions of Service:</h6>
      <p>
      PocketPay. may at any time modifies the terms and conditions ("Terms") of the Services without any prior notification to you. You can access the latest version of the Terms, which shall be updated regularly at the link our website. You undertake to regularly review the Terms. In the event that the modified Terms are not acceptable to you, you shall immediately discontinue using the Services. However, if you continue to use the Services, you hereby agree to accept and be bound by the modified Terms.
      </p>

      <h6 className='mt-5'>Electronic Communication (Email):</h6>
      <p>
      Your consent to receiving communications from PocketPay electronically. Will communicates with Users by email or by posting offers on the Site.
      </p>
      <p>You further agree and undertake that you are accessing the services available on this Site and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this Site.</p>
      <p>All orders shall be pre-approved with an acceptable method of payment as available through payment service providers connected with Web Soft, which may call for and require additional verification or information.</p>
      <p>Web Soft follows a strict verification policy to protect your interests and avoid fraudulent transactions on the site. You agree to provide copies of your credit card, statements or related documents to authenticate your transaction if asked for.</p>
      <p>The address at which delivery of the product ordered by you is to be made should be correct and proper in all respects.</p>
      <p>After the receipt of payment from the Buyer, we shall arrange for the delivery of the product to the buyer at the shipping address provided by the Buyer.</p>
      <p>Any and all orders placed by you on this Site are a firm commitment to purchase and you are obligated to complete the transaction and not contest it in anyway.</p>
      <p>Before placing an order you are advised to check the product description carefully. By placing an order for a product you agree to be bound by the conditions of sale included in the item's description.</p>

      <h6 className='mt-5'>Credit card details:</h6>
      <p>
      You agree, understand and confirm that the credit card details provided by you for availing of services on PocketPay will b e correct and accurate and you shall not use the credit card which is not lawfully owned by you. You further agree and undertake to provide the correct and valid credit card details to EBS Payment Gateway. Further the said information will be not being utilized and shared by us with any of the third parties unless required by law, regulation or court order.
      </p>


      <h6 className='mt-5'>Cancellation & Refund Policy</h6>
      <p>
      Please note that there may be certain orders that we are unable to process and must cancel even if approved. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Some situations that may result in your order being cancelled include unavailability of stocks, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order. In case we are not able to process your order within 15 working days we will initiate a refund.
      </p>
      <p>
      If your order is cancelled after the payment was made by Credit Card or Net-Banking, we will refund the Credit Card or Net-Banking account. If the payment mode was Cheque or DD, we will send you a cheque (not a DD). Cheques will be sent only to the billing address of the customer.
      </p>

      <p>
      In case we receive a cancellation notice from the customer and the order has not been processed/ approved by us, we shall cancel the order and refund the entire amount. We will not be able to cancel orders that have already been processed and shipped out.
      </p>
      <p>In case of online cancellation of services cancellation & refund will be processed as per rules & regulations of respective authority.</p>
    </div>
  );
};



export default TermsAndConditions;

import React from 'react'

const ServicePartOne = () => {


    return (

        <div className="service-part-1  mb-5">
        <div className="container">
            <div className="mil-out-frame mil-p-160-100">
                <div className="row align-items-end">
                    <div className="col-xl-8 mil-mb-80-adaptive-30">
                        <h2 className="mil-up">Innovation and Efficiency in Every Transaction</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 mil-mb-60">
                        <div className="mil-icon-box">
                            <img src="/Images/icons/1.svg" alt="icon" className="mil-mb-30 mil-up" />
                            <h5 className="mil-mb-30 mil-up">Unmatched Speed</h5>
                            <p className="mil-text-m mil-soft mil-up">
                                Make instant transfers and experience <br />
                                Plax's unparalleled speed with every transaction.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 mil-mb-60">
                        <div className="mil-icon-box">
                            <img src="/Images/icons/2.svg" alt="icon" className="mil-mb-30 mil-up" />
                            <h5 className="mil-mb-30 mil-up">Extensive Global Network</h5>
                            <p className="mil-text-m mil-soft mil-up">
                                Connect with the world through our <br />
                                global network that spans more than <br />
                                170 countries.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 mil-mb-60">
                        <div className="mil-icon-box">
                            <img src="/Images/icons/3.svg" alt="icon" className="mil-mb-30 mil-up" />
                            <h5 className="mil-mb-30 mil-up">Advanced Security</h5>
                            <p className="mil-text-m mil-soft mil-up">
                                Protect your assets with our robust <br />
                                security protocols and cutting-edge <br />
                                technologies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );

}

export default ServicePartOne
